<template>
  <div>
   
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
      /
      <!-- <router-link :to="{name: 'admin-master' }" class="root">
               Librarian
            </router-link> -->
      <span class="child"> Librarian / Book Usage Statistics </span>
    </v-row>
    <br />
 <!-- <v-row justify="end" class="ma-3" @click="refresh()">
 <v-btn class="info">
Refresh
  </v-btn>
 </v-row>-->
     <v-row >
     <!-- <v-col cols="4" >
     
    <v-autocomplete
      class="m-2" flat hide-details small dense outlined 
      v-model="organization"
      label="Organization"
      :items="orgList"
      item-text="organization"
      item-value="id"
       @change="selectedOrganization()"
      >
     > </v-autocomplete>
     </v-col>
      <v-col cols="4" >
      <v-combobox 
      class="m-2" flat hide-details small dense outlined 
      v-model="department"
      label="Department"
      :items="department"
      item-text="department"
      item-value="id"
      prepend-inner-icon="mdi-view-list"
    multiple
      chips
        
      >
     </v-combobox> 
     
        </v-col> -->
    <v-col cols="4" >
    </v-col>
    <v-col cols="4" >
    <select id="test" class="custom-select dropdown1 mt-2"  @click="atgraphChange()">
      <option value="mt-n5">--- Please Select Chart Type---</option>
      <option value="pie">Pie Chart</option>
      <option value="bar">Bar Chart</option>
    </select>
     </v-col>
     </v-row>
     
    <v-row  >
      <v-col class="cols-6" id="pie" >
        <v-card class="card">
          <v-card-title class="heading justify-center">
            Books Status Chart
          </v-card-title>
          <br />
          <div>
           <BarChart></BarChart>
          </div>
        </v-card>
      </v-col>
      <v-col class="cols-6" id="bar">
        <v-card class="card">
          <v-card-title class="heading justify-center">
            Books Status Chart
          </v-card-title>
          <br />
          <div>
            <BarChart1></BarChart1>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
 import axios from "axios";  
import BarChart from "@/components/Librarian/BookChart";
import BarChart1 from "@/components/Librarian/BookChart1";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    organization:"",
    department:[],
    selected_org_id:"",
     orgList: [],
     pgmList:[],
     
  }),

  components: {
    BarChart,
    BarChart1,
  },
  methods: {

refresh()
{
       axios
            .post("/IssueBook/refreshstatus")
            .then((res) => {
                if (res.data.msg == "200") {
               location.reload();
                }
            })
        
},

    atgraphChange(){
       $('#test').on('change', function() {
   if(this.value == "pie") {
        $('#pie').show();
        $('#bar').hide();
      } else {
        $('#pie').hide();
        $('#bar').show();
      }
});
    },
   
    selectedOrganization(){
       const data = {
        selected_org_id: this.organization,
      };
      axios
        .post("/IssueBook/getOrgList", data)
        .then((res) => {
          window.console.log(res);
          if (res.data.msg == "200") {
             this.department = res.data.department;
            
          } else {
            this.message = res.data.msg;
          }
        })
        .catch((error) => {
          window.console.log("error:" + error);
        });
    },
       getOrgFilter() {
        axios
            .post("/IssueBook/getOrgData")
            .then((res) => {
                if (res.data.msg == "200") {
                  this.orgList=res.data.org_list
                  this.pgmList=res.data.pgmList
                  //alert(JSON.stringify(this.pgmList))
                  //alert(this.orgList)
                }
            })
            .catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                window.console.log(error);
            })
            .finally(() => {
            });
    },  
  },
 
   mounted() {
    this.getOrgFilter()
   
    },
};
</script>
<style scoped>
.dropdown1 {
width:290px;
overflow:scroll;
height: 5px;
}
select.custom-select {
    color: #252425;
    padding: 10px 30px;
    width: 100%;
    height: 41px;
    border: 1px solid #252425;
    border-radius: 4px;
    font-weight: 700 !important;
    font-size: 16px !important;
}

select.custom-select option {
    padding: 10px 30px;
    font-weight: 400 !important;
    font-size: 16px !important;
 
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.cus-card {
  background: #d3d3d33d;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  padding: 1rem;
  border: 2px solid gray;
}
.c-label {
  font-weight: bold;
}
</style>
